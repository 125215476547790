import { fbConfig } from './webApp.js'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from 'firebase/auth'

const app = initializeApp(fbConfig)
const auth = getAuth(app)
const authProvider = new GoogleAuthProvider()

//send back an idToken and uid to the server
onAuthStateChanged(auth, async(user) => {
  if (user) {
    // await openIDB()
    console.log(user);
    console.log(`Hello ${user.displayName}`)
    console.log(`Your email is ${user.email}`)
    auth.currentUser
      .getIdToken(true)
      .then(async(idToken) => {

        //used by the greetings page
        localStorage.setItem('serverURL',location.origin)
        localStorage.setItem('userName',user.displayName)
        localStorage.setItem('userEmail',user.email)

        //set the callback url
        let url = location.pathname === '/' ? `${location.href}home` : location.href

        localStorage.setItem('urlSearch',location.search)

        document.cookie = `idToken=${idToken}`

        location.replace(url)
      })
      .catch((err) => console.log(err.message));
  }

  if (!user) {
    console.log(`no user signed, call signIn dialog box...`)
    signInWithPopup(auth, authProvider)
    // signInWithRedirect(auth, authProvider)
      .then(() => {
        return getRedirectResult(auth);
      })
      .then(usr => {
        auth.currentUser
          .getIdToken(true)
          .then((idToken) => token = idToken)
          .catch((err) => console.log(err.message));
      })
      .catch(err => {
        console.log(`error ${err.code}, ${err.message} for email ${err.email} with credential ${err.credential} `);
      });
  }
})